<template>
    <div class="request">
        <Popup 
            class="popup" 
            custom-class="gray-header"
            modal-id="history" 
            :on-close="onModalClose" 
            size="lg" 
            :toggle-status="toggleStatus"
        >
            <template v-slot:header>{{ labels.request_review }}</template>
            <template v-slot:body>
                <div class="pre-text">{{ labels.request_review_text }}</div>
            </template>
            <template v-slot:footer>
                <FormulateInput
                    v-model="includeSubModule"
                    type="checkbox"
                    :label="labels.include_sub_module"
                />
                <div class="buttons">
                    <Button
                        variant="btn-outline-purple"
                        :label="labels.cancel"
                        :action="onModalClose"
                    />
                    <Button
                        variant="btn-purple"
                        :label="labels.request_review"
                        :action="requestReviewMD"
                    />
                </div>
            </template>
        </Popup>
    </div>
</template>

<script>
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button";

import { mapActions, mapGetters } from "vuex";

export default {
    name: 'Request',
    components: {
        Popup,
        Button,
    },
    data() {
        return {
            toggleStatus: false,
            includeSubModule: false
        }
    },
    computed: {

        ...mapGetters('helper', ['labels']),

        moduleId() {
            return this.$route.params.id;
        },
    },
    methods: {
        ...mapActions( 'notification', {
            addNotification: 'addNotification',
        } ),
        ...mapActions("_module", {
            requestReview: "requestReview",
        }),
        requestReviewMD() {
            this.requestReview({
                id:this.moduleId, 
                sub:this.includeSubModule
            }).then( res  => {
                if (res.success) {
                    console.log(res);
                    this.onModalClose();
                    this.addNotification( { variant: 'success', msg: res.msg, labels: this.labels } );
                } else if (res.error) {
                    this.addNotification({ variant: "danger", msg: res.error, labels: this.labels });
                }
            })
            .catch((err) => console.log(err));
        },
        onModalClose() {
            this.$router.push( { name: 'module-editor' } );
            this.toggleStatus = false;
            let element = document.querySelector('.modal-backdrop');
            if ( element ) {
                element.remove();
                document.querySelector('body').classList.remove('modal-open');
            }
        },
    },
    mounted() {
        this.toggleStatus = true;
    },
    beforeDestroy() {
        this.toggleStatus = false;
    },
}
</script>

<style lang="scss" scoped>
    .pre-text{
        white-space: pre-line;
    }
    .buttons {
        display: flex;
    }
    .buttons > * {
        margin: 0.25rem;
    }

    ::v-deep {
        .modal-footer {
            flex-direction: column;
            justify-content: space-between;
            @media (min-width: 576px) {
                flex-direction: row;
            }
            & > * {
                margin: 0;
            }
        }

        .formulate-input {
            .formulate-input-element-decorator {
                margin-bottom: 0;
                cursor: pointer;
            }
            .formulate-input-label {
                cursor: pointer;
            }
        }
    }
</style>